import "./menu.scss";

export default function Menu({ menuOpen, setMenuOpen }) {
  return (
    <div className={"menu " + (menuOpen && "active")}>
      <ul>
        <li>
          <a href="#intro" onClick={() => setMenuOpen(!menuOpen)}>
            Intro
          </a>
        </li>
        <li>
          <a href="#work" onClick={() => setMenuOpen(!menuOpen)}>
            Work
          </a>
        </li>
        <li>
          <a href="#projects" onClick={() => setMenuOpen(!menuOpen)}>
            Projects
          </a>
        </li>
        {/* <li>
          <a href="#drum-pad" onClick={() => setMenuOpen(!menuOpen)}>
            DRUM PAD
          </a>
        </li> */}
        <li>
          <a href="#contact" onClick={() => setMenuOpen(!menuOpen)}>
            Reach Out
          </a>
        </li>
      </ul>
    </div>
  );
}
