import React from "react";
import "./intro.scss";
import Bus from "../../img/bus-final.png";

export default function Intro({ menuOpen, deviceType }) {
  return (
    <div className="intro" id="intro">
      <div className="left">
        <h1>Hey there, I'm</h1>
        <h2>Mikelle Brown.</h2>
        {deviceType === "Mobile" ? (
          <div className="mobile-h3-container">
            <div className="mobile-h3">Full Stack Developer</div>
            <div className="mobile-h3">React Enthusiast</div>
            <div className="mobile-h3">Musician</div>
          </div>
        ) : (
          <h3>Full Stack Developer | React Enthusiast | Musician</h3>
        )}
      </div>
      <div className="right">
        <div className={"imgContainer " + (menuOpen && "active")}>
          <img className="me-img" src={Bus} alt=""></img>
        </div>
      </div>
    </div>
  );
}
