import React from "react";
import "./work.scss";
import Login from "../../img/login-mockup.png";
import Continue from "../../img/continue-mockup.png";
import Continue2 from "../../img/continue2.png";
import HomeScreen from "../../img/light-mockup.png";
import ScheduleWeekday from "../../img/schedule-weekday.png";
import CalendarView from "../../img/schedule-calendar.png";
import ScheduleHours from "../../img/schedule-hours.png";
import DevIcon from "devicon-react-svg";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function Work({ menuOpen, deviceType }) {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const checkMenuOpenDesktop = () => {
    const device = deviceType === "Desktop" && menuOpen;
    return device ? "desktop" : undefined;
  };
  const checkMenuOpenMobile = () => {
    const device = deviceType === "Mobile" && menuOpen;
    return device ? "mobile" : undefined;
  };
  return (
    <div className="work" id="work">
      <Carousel
        swipeable={true}
        responsive={responsive}
        autoPlay={false}
        //autoPlaySpeed={1000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={600}
        containerClass="carousel-container"
        itemClass="item"
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        removeArrowOnDeviceType={[
          "tablet",
          checkMenuOpenDesktop(),
          checkMenuOpenMobile(),
        ]}
      >
        <img src={Continue} alt="" className="img"></img>
        <img src={Login} alt="" className="img"></img>
        <img src={Continue2} alt="" className="img"></img>
        <img src={ScheduleWeekday} alt="" className="img"></img>
        <img src={CalendarView} alt="" className="img"></img>
        <img src={ScheduleHours} alt="" className="img"></img>
      </Carousel>
    </div>
  );
}

function Sweep({ deviceType }) {
  return (
    <div className="sweep" id="work">
      <div className="sweep-left">
        <img src={HomeScreen} alt="" className="light-mode-img"></img>
      </div>
      <div className="sweep-right">
        <div className="header">Current work and technologies</div>
        <div className="tech-icons">
          <DevIcon
            icon="mongodb"
            style={{
              height: deviceType === "Mobile" ? 60 : 100,
              width: deviceType === "Mobile" ? 60 : 100,
              fill: "#d65a31",
            }}
          />
          <DevIcon
            icon="typescript_badge"
            style={{
              height: deviceType === "Mobile" ? 60 : 100,
              width: deviceType === "Mobile" ? 60 : 100,
              fill: "#d65a31",
            }}
          />
          <DevIcon
            icon="react"
            style={{
              height: deviceType === "Mobile" ? 60 : 100,
              width: deviceType === "Mobile" ? 60 : 100,
              fill: "#d65a31",
            }}
          />
          <DevIcon
            icon="heroku"
            style={{
              height: deviceType === "Mobile" ? 60 : 100,
              width: deviceType === "Mobile" ? 60 : 100,
              fill: "#d65a31",
            }}
          />
          <DevIcon
            icon="github"
            style={{
              height: deviceType === "Mobile" ? 60 : 100,
              width: deviceType === "Mobile" ? 60 : 100,
              fill: "#d65a31",
            }}
          />
        </div>

        <div className="sub-header">
          Sweep is based out of Salt Lake City and on a mission to revolutionize
          the home cleaning industry. Our platform connects gig workers with
          customers who enjoy the convenience of on demand and customizable
          cleaning services.
        </div>

        <div className="sub-header">Scroll on to see more.</div>
      </div>
    </div>
  );
}

export { Work, Sweep };
