import React from "react";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "./contact.scss";

const Contact = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const toastifySuccess = () => {
    toast("Email sent!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: "submit-feedback success",
      toastId: "notifyToast",
    });
  };

  const toastifyError = () => {
    toast("Whoops, something went wrong", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: "submit-feedback error",
      toastId: "notifyToast",
    });
  };
  const onSubmit = async (data) => {
    const { name, email, subject, message } = data;
    try {
      const templateParams = {
        name,
        email,
        subject,
        message,
      };
      await emailjs.send(
        "service_2jesmdf",
        "template_azroyxh",
        templateParams,
        "hwQJHwDGJgVnQKPA3"
      );
      reset();
      toastifySuccess();
    } catch (e) {
      console.log(e);
      reset();
      toastifyError();
    }
  };

  return (
    <div className="contact" id="contact">
      <div className="header">Reach Out</div>
      <div className="container">
        <form
          id="contact-form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          className="form"
        >
          {errors.name && (
            <span className="errorMessage message">{errors.name.message}</span>
          )}
          <input
            type="text"
            name="name"
            {...register("name", {
              required: {
                value: true,
                message: "Please enter your name",
              },
              maxLength: {
                value: 30,
                message: "Please use 30 characters or less",
              },
            })}
            className="form-control formInput border-radius"
            placeholder="Name"
            style={{
              backgroundColor: "#393e46",
              height: "50px",
              marginBottom: "15px",
              borderRadius: "10px",
              color: "#eeeeee",
            }}
          ></input>
          {errors.email && (
            <span className="errorMessage message">
              Please enter a valid email address
            </span>
          )}
          <input
            type="email"
            name="email"
            {...register("email", {
              required: true,
              pattern:
                /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            })}
            className="form-control formInput border-radius"
            placeholder="Email address"
            style={{
              backgroundColor: "#393e46",
              height: "50px",
              marginBottom: "15px",
              borderRadius: "10px",
              color: "#eeeeee",
            }}
          ></input>

          {errors.subject && (
            <span className="errorMessage message">
              {errors.subject.message}
            </span>
          )}
          <input
            type="text"
            name="subject"
            {...register("subject", {
              required: {
                value: true,
                message: "Please enter a subject",
              },
              maxLength: {
                value: 75,
                message: "Subject cannot exceed 75 characters",
              },
            })}
            className="form-control formInput border-radius"
            placeholder="Subject"
            style={{
              backgroundColor: "#393e46",
              height: "50px",
              marginBottom: "15px",
              borderRadius: "10px",
              color: "#eeeeee",
            }}
          ></input>
          {errors.message && (
            <span className="errorMessage message">Please enter a message</span>
          )}

          <textarea
            rows={3}
            name="message"
            {...register("message", {
              required: true,
            })}
            className="form-control formInput border-radius"
            placeholder="Message"
            style={{
              backgroundColor: "#393e46",
              height: "100px",
              marginBottom: "15px",
              borderRadius: "10px",
              color: "#eeeeee",
            }}
          ></textarea>
          <button className="btn" type="submit">
            Send
          </button>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Contact;
