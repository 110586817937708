import React from "react";
import "./projects.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import Travel from "../../img/travel-advisor.png";
import IsleOfHope from "../../img/isle-of-hope.png";
import PicnicSlc from "../../img/picnicslc.png";
import DiceGame from "../../img/dice-game.png";
import AdviceGenerator from "../../img/advice-generator.png";
import DevIcon from "devicon-react-svg";

export default function Projects({ menuOpen, deviceType }) {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const checkMenuOpenTablet = () => {
    return menuOpen ? "tablet" : undefined;
  };

  const checkMenuOpenDesktop = () => {
    const device = deviceType === "Desktop" && menuOpen;
    return device ? "desktop" : undefined;
  };
  const checkMenuOpenMobile = () => {
    const device = deviceType === "Mobile" && menuOpen;
    return device ? "mobile" : undefined;
  };

  return (
    <div className="projects" id="projects">
      <div className="header">Projects</div>
      <div className="projects-container">
        <Carousel
          swipeable={true}
          responsive={responsive}
          containerClass="carousel-container"
          itemClass="item"
          ssr={true}
          removeArrowOnDeviceType={[
            checkMenuOpenTablet(),
            checkMenuOpenDesktop(),
            checkMenuOpenMobile(),
          ]}
        >
          <div
            className="project"
            onClick={() =>
              window.open("https://mikelle-travel-advisor.netlify.app/")
            }
          >
            <div className="img">
              <img src={Travel} alt="" className="border"></img>
            </div>
            <div className="tech">
              <div className="tech-header">Travel Advisor</div>
              <div className="tech-items">
                <DevIcon icon="javascript" />
                <DevIcon icon="react" />
                <DevIcon icon="sass" />
              </div>
            </div>
          </div>
          <div
            className="project"
            onClick={() => window.open("https://isleofhopeireland.com/")}
          >
            <div className="img">
              <img src={IsleOfHope} alt="" className="border"></img>
            </div>
            <div className="tech">
              <div className="tech-header">Book Promotion</div>
              <div className="tech-items">
                <DevIcon icon="javascript" />
                <DevIcon icon="react" />
              </div>
            </div>
          </div>
          <div
            className="project"
            onClick={() => window.open("https://picnicslc.com/")}
          >
            <div className="img">
              <img src={PicnicSlc} alt="" className="border"></img>
            </div>
            <div className="tech">
              <div className="tech-header">Picnic Cafe</div>
              <div className="tech-items">
                <DevIcon icon="javascript" />
                <DevIcon icon="react" />
              </div>
            </div>
          </div>
          <div
            className="project"
            onClick={() =>
              window.open("https://mikelle-advice-generator.netlify.app/")
            }
          >
            <div className="img">
              <img src={AdviceGenerator} alt="" className="border"></img>
            </div>
            <div className="tech">
              <div className="tech-header">Advice Generator</div>
              <div className="tech-items">
                <DevIcon icon="javascript" />
                <DevIcon icon="react" />
              </div>
            </div>
          </div>
          <div
            className="project"
            onClick={() => window.open("https://mikelle-dice-game.netlify.app")}
          >
            <div className="img">
              <img src={DiceGame} alt="" className="border"></img>
            </div>
            <div className="tech">
              <div className="tech-header">Dice Game</div>
              <div className="tech-items">
                <DevIcon icon="javascript" />
              </div>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
}
